import { TypographyProps } from '@@/components/Elements/Typography';
import clsx from 'clsx';
import { useState } from 'react';

import { Alert } from '@/components/Elements';
import { isUs, MFA_NOTICE_KEY } from '@/globalConsts';

export type ApplicationBarProps = {
	children: React.ReactElement | string;
	className?: string;
};

export const ApplicationBar = (props: TypographyProps) => {
	const { children, className } = props;
	const [showMfaNotice, setShowMfaNotice] = useState(localStorage.getItem(MFA_NOTICE_KEY));
	const handleClose = () => {
		setShowMfaNotice('false');
		localStorage.setItem(MFA_NOTICE_KEY, 'false');
	};

	return (
		<header
			className={clsx(
				'flex flex-row flex-wrap w-screen box-border shrink-0 fixed top-0 left-auto right-0 z-20',
				className
			)}
		>
			<div className='min-h-fit w-screen pl-6 pr-6 relative flex justify-between items-center h-20 bg-primaryBackground border-b-2 border-secondaryBorder'>
				{children}
			</div>
			{showMfaNotice === 'true' && (
				<Alert
					severity='info'
					title='What is new?'
					showCloseButton={true}
					handleClose={handleClose}
					className='border-none rounded-none'
				>
					To help protect your account, we’re introducing Multi-Factor Authentication
					(MFA), which will be required for all users. This extra layer of protection
					helps prevent {isUs ? 'unauthorized' : 'unauthorised'} access and keeps your and
					patients’ accounts information safer. Soon, you’ll need to enable MFA the next
					time you log in. Thank you for helping us keep your account safe!
				</Alert>
			)}
		</header>
	);
};
